@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
    overflow-x: hidden;
    scroll-behavior: smooth;
}

@layer base {
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }
}

.scroll-snap-container {
    scroll-snap-type: x mandatory;
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-align: start;
    width: 100%;
    display: flex;
}
.card {
    scroll-snap-align: center;
    flex: 0 0 auto;
}

.PhoneInputInput {
    border: none;
    outline: none;
}

.filled-star path {
    fill: gold;
}

@media screen and (min-width: 645px) {
    .fancy-scrollbar {
        margin-right: -15px;
    }

    /* width */
    .fancy-scrollbar::-webkit-scrollbar {
        width: 15px;
    }

    /* Track */
    .fancy-scrollbar::-webkit-scrollbar-track {
        background: #e8ebec;
        border-radius: 10px;
    }

    /* Handle */
    .fancy-scrollbar::-webkit-scrollbar-thumb {
        background: #25253c;
        border-radius: 10px;
    }
}

.carousel.carousel-slider .control-arrow {
    width: 100px;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
    border-top: 20px solid transparent !important;
    border-bottom: 20px solid transparent !important;
}

.carousel .control-prev.control-arrow:before {
    border-right: 20px solid #fff !important;
}

.carousel .control-next.control-arrow:before {
    border-left: 20px solid #fff !important;
}


